import React, { useState } from "react"
import cx from "classnames"

import sanitize from "~/helpers/sanitize"
import Button from "~/ui/elements/button/button"
import Check from "~/ui/identity/icon/check"

type Props = {
  className?: string
  title: string
  description: string
}

enum State {
  Default,
  Submitting,
  Submitted,
}

function Contact({ className, title, description }: Props) {
  const [state, setState] = useState(State.Default)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    message: false,
  })

  async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    const endpoint = process.env.GATSBY_GETFORM as string

    const data = {
      type: "contact",
      name,
      email,
      message,
    }

    setErrors({
      name: name.length == 0,
      email: email.length == 0,
      message: message.length == 0,
    })

    if (name.length == 0 || email.length == 0 || message.length == 0) {
      return
    }

    try {
      setState(State.Submitting)

      let response = await fetch(endpoint, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      setState(State.Submitted)
    } catch (error) {
      setState(State.Default)
    }
  }

  return (
    <section className={cx("bg-white", className)}>
      <div className="lg:flex gap-5 max-w-screen-xl mx-auto py-10 md:py-16 px-4 md:px-10 xl:px-20">
        <div className="flex-1 mb-8 lg:mb-0">
          <div className="lg:max-w-md">
            <h2 className="text-3xl font-bold text-font-1 lg:text-6xl">
              {title}
            </h2>

            <p
              className="mt-2 text-sm text-font-2 lg:text-lg"
              dangerouslySetInnerHTML={sanitize(description)}
            ></p>
          </div>
        </div>

        <div className="flex-1">
          <form
            className="w-full p-4 lg:p-6 bg-blue-500 rounded-3xl"
            onSubmit={e => onSubmit(e)}
          >
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block mb-3 text-lg font-medium text-font-1"
                  htmlFor="name"
                >
                  Name:
                </label>
                <input
                  className={cx(
                    "appearance-none block w-full py-3 px-4 rounded-lg focus:outline-font-1 text-font-1",
                    {
                      "border-red-400 border-2": errors.name,
                    }
                  )}
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Enter Name"
                  value={name}
                  onChange={e => {
                    setErrors({
                      ...errors,
                      name: false,
                    })
                    setName(e.target.value)
                  }}
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block mb-3 text-lg font-medium text-font-1"
                  htmlFor="email"
                >
                  Email:
                </label>
                <input
                  className={cx(
                    "appearance-none block w-full py-3 px-4 rounded-lg focus:outline-font-1 text-font-1",
                    {
                      "border-red-400 border-2": errors.email,
                    }
                  )}
                  id="email"
                  name="email"
                  type="text"
                  placeholder="Enter Email"
                  value={email}
                  onChange={e => {
                    setErrors({
                      ...errors,
                      email: false,
                    })
                    setEmail(e.target.value)
                  }}
                />
              </div>
            </div>

            <div className="mb-8 flex flex-wrap -mx-3">
              <div className="w-full px-3">
                <label
                  className="block mb-3 text-lg font-medium text-font-1"
                  htmlFor="message"
                >
                  Message
                </label>
                <textarea
                  className={cx(
                    "h-36 appearance-none block w-full py-3 px-4 rounded-lg focus:outline-font-1 text-font-1",
                    {
                      "border-red-400 border-2": errors.message,
                    }
                  )}
                  id="message"
                  name="message"
                  placeholder="Enter Message"
                  value={message}
                  onChange={e => {
                    setErrors({
                      ...errors,
                      message: false,
                    })
                    setMessage(e.target.value)
                  }}
                ></textarea>
              </div>
            </div>
            <div>
              <Button
                type="primary"
                className={cx("inline-block", {
                  "pointer-events-none": state != State.Default,
                })}
              >
                <div className="flex gap-3 items-center">
                  {state == State.Submitting && <div className="loader"></div>}
                  {state == State.Submitted && <Check width={24} />}
                  <div>Send Message</div>
                </div>
              </Button>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default Contact
