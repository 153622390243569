import React from "react"
import Loadable from "@loadable/component"
import Breadcrumbs from "~/ui/compositions/navigation/breadcrumb"
import Page from "~/models/page"
import Author from "~/models/author"

import SEO from "~/ui/helpers/seo"
import Frame from "~/ui/layout/frame"
import PageCoverBlock from "~/ui/compositions/generic/page-cover"
import ProfileBlock from "~/ui/compositions/author/profile"
import ListBlock from "~/ui/compositions/generic/list"
import TeamBlock from "~/ui/compositions/generic/team"
import Contact from "~/ui/compositions/generic/contact"

import { graphql } from "gatsby"
import {
  ProfilePageQuery,
  ContentfulPage,
  ContentfulAuthor,
} from "../../../types/graphql-types"

export const query = graphql`
  query ProfilePage($id: String, $slug: String) {
    contentfulPage(id: { eq: $id }) {
      ...PageFields
    }
    contentfulAuthor(slug: { eq: $slug }) {
      ...AuthorFields
    }
    allContentfulAuthor(sort: { fields: contentful_id }) {
      nodes {
        ...AuthorFields
      }
    }
  }
`

type Props = {
  data: ProfilePageQuery
}

const ProfilePage = ({ data }: Props) => {
  const Analytics = Loadable(() => import("../helpers/analytics"))

  const page = new Page(data.contentfulPage as ContentfulPage)
  const author = new Author(data.contentfulAuthor as ContentfulAuthor)
  const authors = data.allContentfulAuthor.nodes.map(author => {
    return new Author(author as ContentfulAuthor)
  })

  return (
    <Frame>
      <Analytics page={page} />
      <React.Fragment>
        <SEO page={page} />
        {page.blocks.map(block => (
          <React.Fragment key={block.id}>
            {block.name == "PageCover" && (
              <React.Fragment>
                <PageCoverBlock color="green" {...block.props} />
                <div className="mb-2 mt-2">
                  <div className="relative justify-between md:flex items-center max-w-screen-xl mx-auto py-7 lg:py-0 px-4 md:px-10 xl:px-20">
                    <Breadcrumbs breadcrumbs={page.breadcrumbs} />
                  </div>
                </div>
              </React.Fragment>
            )}

            {block.name == "Profile" && (
              <ProfileBlock author={author} {...block.props} />
            )}
            {block.name == "List" && <ListBlock {...block.props} />}
            {block.name == "Team" && (
              <TeamBlock {...block.props} authors={authors} />
            )}
            {block.name == "Contact" && <Contact {...block.props} />}
          </React.Fragment>
        ))}
      </React.Fragment>
    </Frame>
  )
}

export default ProfilePage
