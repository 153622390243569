import React from "react"

type Props = {
  className?: string
  width?: number
  fill?: string
}

function Check(props: Props) {
  return (
    <svg
      width={props.width}
      className={props.className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0C3.5875 0 0 3.5875 0 8C0 12.4125 3.5875 16 8 16C12.4125 16 16 12.4125 16 8C16 3.5875 12.4125 0 8 0Z"
        fill="#E6FAF4"
      />
      <path
        d="M12.0562 6.30625L7.72187 10.6375C7.59062 10.7688 7.42187 10.8344 7.25 10.8344C7.07812 10.8344 6.90937 10.7688 6.77812 10.6375L4.6125 8.47188C4.35313 8.2125 4.35313 7.79062 4.6125 7.52812C4.87187 7.26875 5.29375 7.26875 5.55625 7.52812L7.25312 9.225L11.1156 5.3625C11.375 5.10312 11.7969 5.10312 12.0594 5.3625C12.3156 5.62188 12.3156 6.04375 12.0562 6.30625Z"
        fill="#00D193"
      />
    </svg>
  )
}

export default Check
