import React from "react"
import cx from "classnames"

import Author from "~/models/author"
import Image from "~/ui/elements/image/image"
import sanitize from "~/helpers/sanitize"

type Props = {
  className?: string
  title: string
  description: string
  author: Author
}

function Profile({ title, description, author, className }: Props) {
  return (
    <section className={cx("overflow-hidden py-12 md:py-20", className)}>
      <div className="relative max-w-screen-xl mx-auto px-4 md:px-10 xl:px-20">
        <div className="mb-8">
          <p className="text-lg text-blue-600 font-medium mb-2">OUR TEAM</p>
          <h2 className="text-4xl text-font-1 font-bold">{title}</h2>
        </div>
        <div className="flex-col-reverse sm:flex-row flex gap-10 items-start">
          <p
            className="flex-1 text-font-2 md:text-lg"
            dangerouslySetInnerHTML={sanitize(description)}
          ></p>
          <Image
            className="w-full sm:w-auto aspect-[1/0.8] sm:aspect-auto object-top flex-1 block shrink-0 rounded overflow-hidden"
            gatsbyImageData={author.headshot}
            alt={author.name}
          />
        </div>
      </div>
    </section>
  )
}

export default Profile
