import React from "react"
import sanitize from "~/helpers/sanitize"
import cx from "classnames"
import { Link } from "gatsby"

import Author from "~/models/author"
import Image from "~/ui/elements/image/image"

type Props = {
  className?: string
  title: string
  description?: string
  authors: Author[]
  background: string
}

function Team({ authors, title, description, background }: Props) {
  return (
    <section
      className={cx("overflow-hidden py-12 md:py-20", {
        "bg-yellow-200": background == "yellow",
      })}
    >
      <div className="lg:flex gap-10 relative max-w-screen-xl mx-auto px-4 md:px-10 xl:px-20">
        {title && (
          <div className="lg:max-w-[738px] mx-auto relative mb-8 z-10">
            <h2
              className="text-2xl font-bold text-font-1 lg:text-4xl text-left tracking-[-0.025em]"
              dangerouslySetInnerHTML={sanitize(title)}
            ></h2>

            {description && (
              <p
                className="mt-3 text-font-1 text-xl text-left"
                dangerouslySetInnerHTML={sanitize(description)}
              ></p>
            )}
          </div>
        )}

        <ul className="relative z-10">
          {authors.map(author => (
            <li
              key={author.id}
              className="border-b border-gray-20 last:border-none pt-8 first:pt-0"
            >
              {
                author.enableLinkForAuthor == "true" ? 
                <Link
                  to={`/about-us/${author.slug}`}
                  className="flex flex-col sm:flex-row items-start gap-6 pb-7 border-b border-gray-20 last:border-none"
                >
                  <div className="aspect-[1/0.8] w-full sm:h-auto sm:w-[167px] shrink-0 rounded-lg overflow-hidden shadow-author">
                    <Image
                      className="block grayscale"
                      gatsbyImageData={author.headshot}
                      alt={author.name}
                    />
                  </div>
                  <div>
                    <p className="font-bold text-font-1 text-lg">{author.name}</p>
                    <p className="font-bold text-blue-600 text-lg mb-4">
                      {author.title}
                    </p>
                    <p className="text-font-2 text-lg">{author.description}</p>
                  </div>
                </Link>
              :
                <div
                  className="flex flex-col sm:flex-row items-start gap-6 pb-7 border-b border-gray-20 last:border-none"
                >
                  <div className="aspect-[1/0.8] w-full sm:h-auto sm:w-[167px] shrink-0 rounded-lg overflow-hidden shadow-author">
                    <Image
                      className="block grayscale"
                      gatsbyImageData={author.headshot}
                      alt={author.name}
                    />
                  </div>

                  <div>
                    <p className="font-bold text-font-1 text-lg">{author.name}</p>
                    <p className="font-bold text-blue-600 text-lg mb-4">
                      {author.title}
                    </p>
                    <p className="text-font-2 text-lg">{author.description}</p>
                  </div>
                </div>
              }

            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}

export default Team
