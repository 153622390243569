import React from "react"

import TitledSection from "~/ui/layout/titled-section"

type Props = {
  title: string
  description?: string
  background: string
  items: any
}

function List({ title, description, items, background }: Props) {
  return (
    <TitledSection
      title={title}
      description={description}
      background={background}
    >
      <ul className="max-w-[738px] mx-auto">
        {items.map((item: any) => (
          <li key={item.title} className="bg-yellow-300 p-7 rounded-3xl mb-6">
            <h3 className="text-lg md:text-2xl text-font-1 font-bold mb-2 md:mb-4">
              {item.title}
            </h3>
            <p className="md:text-lg text-font-2">{item.body}</p>
          </li>
        ))}
      </ul>
    </TitledSection>
  )
}

export default List
